footer {
  margin-top: 10vmax;
  padding: 2vmax;
  background-color: rgb(34, 33, 33);
  color: white;
  display: flex;
  align-items: center;
}

.leftFooter {
  width: 20%;
  display: flex;

  flex-direction: column;
  align-items: center;
}
.leftFooter > h4 {
  font-family: "Roboto";
  font-size: 1vmax;
}
.leftFooter > p {
  text-align: center;
  font-size: 1.2vmax;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.leftFooter > img {
  width: 10vmax;
  margin: 1vmax;
  cursor: pointer;
}

.midFooter {
  width: 60%;

  text-align: center;
}

.midFooter > h1 {
  font-size: 4vmax;
  font-family: "Roboto";
  color: #39a8e9;
}
.midFooter > p {
  max-width: 60%;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin: 1vmax auto;
}

.rightFooter {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.rightFooter > h4 {
  font-family: "Roboto";
  font-size: 1.4vmax;
  text-decoration: underline;
}
.rightFooter > a {
  text-decoration: none;
  font-size: 1.3vmax;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: white;
  transition: all 0.5s;
  margin: 0.5vmax;
}

.rightFooter > a:hover {
  color: #eb4034;
}

.rightFooter > a > img{
  height:20px;
  width:20px;

}
