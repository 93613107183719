.profileContainer {
    display: flex;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0%;
    left: 0%;
    max-width: 100%;
    background-image: url(../../images/bg.png) ; 
  
    color: #f9fafb;
    background-position: top;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
  }
  
  .profileContainer > div {
    display: flex;
    height: 100vh;
    width: 100vw;
    max-width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .profileContainer > div:first-child > h1 {
    color: rgb(253, 253, 253);
    font: 500 2.2vmax "Roboto";
    transform: translateX(-10vmax) translateY(-2vmax);
  }
  
  .profileContainer > div:first-child > img {
    width: 30vmax;
    height: 20vmax;
    border-radius: 0%;
    transition: all 0.5s;
    box-shadow: 20px 30px 30px rgba(255, 255, 255, 0.544);

  }
  
  .profileContainer > div:first-child > img:hover {
    transform: scale(1.05);
    box-shadow: 10px 10px 20px rgb(255, 255, 255);
  }
  
  .profileContainer > div:first-child > a {
    border: none;
    background-color: tomato;
    font: 400 1vmax "Roboto";
    color: white;
    text-decoration: none;
    padding: 0.5vmax;
    width: 30%;
    margin: 4vmax;
    text-align: center;
    transition: all 0.5s;
  }
  /* p{
    color: white;
  } */
  
  .profileContainer > div:first-child > a:hover {
    background-color: rgb(204, 78, 56);
  }
  
  .profileContainer > div:last-child {
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 5vmax;
    box-sizing: border-box;
  }
  
  .profileContainer > div:last-child > div > h4 {
    color: rgb(255, 255, 255);
    box-shadow: 0px 10px 20px rgba(255, 255, 255, 0.065);
    width:20vh;
    font: 400 1.2vmax "Roboto";
  }
  
  .profileContainer > div:last-child > div > p {
    color: rgb(235, 236, 228);
    font: 400 1vmax cursive;
    margin: 0.2vmax;
  }
  
  .profileContainer > div:last-child > div:last-child {
    display: flex;
    flex-direction: column;
    width: 60%;
  }
  
  .profileContainer > div:last-child > div:last-child > a {
    border: none;
    background-color: rgb(68, 68, 68);
    font: 400 1vmax "Roboto";
    color: white;
    text-decoration: none;
    padding: 0.5vmax;
    text-align: center;
    transition: all 0.5s;
    margin: 1vmax 0;
  }
  
  .profileContainer > div:last-child > div:last-child > a:hover {
    background-color: rgb(31, 31, 31);
  }
  
  @media screen and (max-width: 600px) {
    .profileContainer {
      flex-direction: column;
    }
  
    .profileContainer > div:first-child > h1 {
      font: 500 3.2vmax "Roboto";
      transform: translateY(-2vmax);
    }
  
    .profileContainer > div:first-child > a {
      font: 400 1.7vmax "Roboto";
      padding: 1vmax;
    }
  
    .profileContainer > div:last-child {
      text-align: center;
      align-items: center;
    }
  
    .profileContainer > div:last-child > div > h4 {
      font: 400 2.5vmax "Roboto";
    }
  
    .profileContainer > div:last-child > div > p {
      font: 400 2vmax cursive;
      margin: 0.5vmax 0;
    }
  
    .profileContainer > div:last-child > div:last-child > a {
      font: 400 1.8vmax "Roboto";
      padding: 1vmax;
      margin: 2vmax 0;
    }
  }
  