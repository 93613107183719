.container{
  margin:10px;
  padding: 0px;
  box-sizing: border-box;
  /* background: linear-gradient(0.25turn, #24305E, #A8D0e6); */
  
}

.banner {
  background: linear-gradient(0.25turn, rgb(20, 116, 140), #A8D0e6,rgb(20, 116, 140));
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  color: rgb(64, 72, 74);
}

.banner > h1 {
  margin: 5vmax;
  font: 600 3vmax "Roboto";
}

.banner > p {
  font: 300 2vmax "Lucida Sans";
}
.banner > img {
  margin:10px;
  height: 15vh;
  width:20vh;
}

.banner > a > button {
  margin-bottom: 5vmax;
  cursor: pointer;
  background-color: #F76c6c;
  border-radius:5px;
  border: 1px solid white;
  padding: 1vmax;
  transition: all 0.8s;
  width: 15vmax;
  font: 500 1vmax "Roboto";
}
.banner > a > button:hover {
  background-color: rgba(255, 255, 255, 0);
  /* background-color: #24305E; */
  color: white;
}

.banner::after {
  content: "";
  width: 100vw;
  height: 95vmin;
  /* background-color:rgb(202, 102, 89); */
  background-color:white;
  position: absolute;
  top: 0%;
  left: 0;
  clip-path: polygon(100% 83%, 0% 100%, 100% 100%);
  max-width: 100%;
}

.homeHeading {
  text-align: center;
  font-family: Roboto;
  font-size: 1.4vmax;
  border-bottom: 1px solid rgba(21, 21, 21, 0.5);
  width: 20vmax;
  padding: 1vmax;
  margin: 5vmax auto;
  color: rgb(0, 0, 0, 0.7);
}

.container {
  display: flex;
  margin: 2vmax auto;
  width: 100vw;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 100%;
}

.productCard {
  width: 15vmax;
  height:auto;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: rgb(48, 48, 48);
  border: 2px solid rgba(0, 0, 0, 0.051);
  padding: 2px;
  margin: 2vmax;
  transition: all 0.5s;
  padding-bottom: 0.5vmax;
}

.productCard > img {
  width: 14vmax;
}

.productCard > div {
  margin: 0.5vmax;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.productCardSpan {
  margin: 0.5vmax;
  font: 300 0.7vmax "Roboto";
}

.productCard > p {
  font-family: "Roboto";
  font-size: 1.2vmax;
  margin: 1vmax 0.5vmax;
  margin-bottom: 0;
}

.productCard > span {
  margin: 0.5vmax;
  color: rgb(69, 101, 113);
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 1vmax;
}

.productCard:hover {
  box-shadow: 0 0 5px rgba(15, 15, 15, 0.26);

  transform: translateY(-1vmax);
}

@media screen and (max-width: 600px) {

  .banner {
    /* background: linear-gradient(0.25turn, rgb(20, 116, 140), #A8D0e6); */
    height: 50vh;
    /* display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: white; */
  }

  /* .banner::after {
    width:0%;
    z-index :-9333333333333333333;
  } */

  .productCard > p {
    font-size: 1.7vmax;
  }

  .productCard > div {
    margin: 0vmax;
    display: block;
  }

  .productCard > span {
    font-size: 1.5vmax;
  }

  .productCard > div > span {
    margin: 0 0.5vmax;
    font: 300 1vmax "Roboto";
  }
}
@media screen and (max-width: 690px) {

  .banner::after {
    content: "";
    width: 100vw;
    height: 150vmin;
    background-color:rgb(202, 102, 89);
    background-color:rgb(20, 116, 140);
    position: absolute;
    top: 0%;
    left: 0;
    /* clip-path: polygon(100% 83%, 0% 100%, 100% 100%); */
    max-width: 100%;
    z-index :-13;
  }

  .productCard > p {
    font-size: 1.7vmax;
  }

  .productCard > div {
    margin: 0vmax;
    display: block;
  }

  .productCard > span {
    font-size: 1.5vmax;
  }

  .productCard > div > span {
    margin: 0 0.5vmax;
    font: 300 1vmax "Roboto";
  }
}
