.speedDial {
    position: fixed;
    right: 3vmax;
    top: 3vmax;
  }
  
  .speedDialIcon {
    width: 12vh;
    height: 12vh;
    border-radius: 100%;
  }
  